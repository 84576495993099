@import url('https://use.typekit.net/ntx1nrn.css');
@import url('//hello.myfonts.net/count/3b743d');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&family=Montserrat:wght@400;800&family=Averia+Sans+Libre:ital@1&display=swap');

// Treps styles
@import '../colors';
@import '../mixins';

.rubik--font {
  @extend .rubik--font;

  font-weight: 400;
  letter-spacing: 0;
}

.application {
  @extend .rubik--font;
}

.theme--Treps {
  ::selection {
    color: white;
    background-color: $color-treps-blue;
  }

  h1 {
    @extend .aesthet--font;

    line-height: 1.2;
  }
  h2 {
    @extend .aesthet--font;

    @include font-size(36px);

    margin-bottom: 0.5em;
    line-height: 1.2;
  }
  h3 {
    @extend .aesthet--font;

    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
  }
  h4 {
    @extend .rubik--font;

    margin-bottom: 0.5em;
    font-weight: 500;
    line-height: 1.2;
  }
  .header-4 {
    @extend .freight-pro--font;

    @include font-size(74px);

    line-height: 0.95 !important;
  }

  .header-3 {
    @extend .aesthet--font;

    line-height: 0.9 !important;
    letter-spacing: 0 !important;
  }

  .header-2 {
    @extend .freight-pro--font;

    @include font-size(40px, 0.7, 0.75, 0.85);

    line-height: 1 !important;
    text-transform: uppercase;
    letter-spacing: 0.074em !important;
  }

  .header-1 {
    @extend .aesthet--font;

    @include font-size(36px, 0.8, 0.8, 0.8);

    line-height: 1.1 !important;
  }

  .hero-text {
    @extend .aesthet--font;

    @include font-size(64px);

    // margin-top: 0;
    // margin-bottom: 0;
    line-height: 1.1;
  }

  .hero-hours {
    @extend .aesthet--font;

    @include font-size(40px, 0.6, 0.6, 0.6);

    margin-top: 0.8em;
    // margin-bottom: 0;
    line-height: 1.2;
  }

  .r-headline {
    @extend .rubik--font;

    font-weight: 400;

    @include font-size(34px);

    line-height: 1.3 !important;
  }

  .r-title {
    @extend .rubik--font;

    font-weight: 400;

    @include font-size(24px, 0.8, 0.8, 0.8);

    line-height: 1.4 !important;
  }

  .page-title {
    @extend .motor--font;

    @include font-size(40px, 0.8, 0.8, 0.8);

    text-transform: uppercase;
  }

  .btn-base:not(.pa-0):not(.v-btn--icon):not(.v-btn--floating):not(.footer-nav-btn):not(.ui) {
    @extend .rubik--font;

    font-weight: 500;
    letter-spacing: 0.043em;

    @include btn-size(1rem, 1.5rem);

    &.v-btn--small {
      @include btn-size(0.85rem, 1rem);

      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &.v-btn--large {
      @extend .rubik--font;

      @include btn-size(1.5rem);

      font-weight: 500 !important;
      line-height: 1.4;
      letter-spacing: 0.043em;
    }
  }
} // .theme--Treps
